@use 'sass:map';

@import "../common/price-discount-mixins";

$arrangementOfTheWeek-background: $gray-light !default;
$arrangementOfTheWeekmobile-background: rgba(240,241,243,0.88) !default;
$arrangementOfTheWeek-border-color: $white !default;
$arrangementOfTheWeek-title-color: $primary-blue !default;
$arrangementOfTheWeek-location-color: $gray-text-default !default;
$arrangementOfTheWeek-i-color: $primary-blue !default;
$arrangementOfTheWeek-detail-text-color: $gray-text-default !default;
$arrangementOfTheWeek-price-before-text-color: $gray-text-default !default;
$arrangementOfTheWeek-price-text-color: $primary-blue !default;
$arrangementOfTheWeek-date-background: $secondary-green !default;
$arrangementOfTheWeek-date-color: $white !default;
$arrangementOfTheWeekin-Price-background: $secondary-green !default;
$arrangementOfTheWeekin-Price-color: $white !default;
$arrangementOfTheWeek-bookBtn-background: $primary-blue !default;
$arrangementOfTheWeek-bookBtn-hover-background: $secondary-green !default;
$arrangementOfTheWeek-bookBtn-color: $white !default;

.row {
	margin: 0;
	background-color: $arrangementOfTheWeek-background;

	.container-1 {
		padding: 0;

		img {
			width: 100%;
			height: auto;
		}
	}

	.container-2 {
		padding: 0;

		.row:nth-child(1) {
			padding: 15px;

			@include media-breakpoint-down(lg) {
				position: absolute;
				bottom: 0;
				width: 100%;
				background-color: $arrangementOfTheWeekmobile-background;

				div:nth-child(2) {
					.date, .in-price {
						display: inline-block;
						float: left;
						width: 50%;
						border: none;
					}

					.in-price {
						border-left: 1px solid $arrangementOfTheWeek-border-color;
					}
				}
			}

			@include media-breakpoint-down(md) {
				position: relative;
			}
		}

		[class*='col-'] {
			padding: 0;
			margin: 0;
		}

		A {
			text-decoration: none;

			H3.title {
				display: block;
				margin: 0 0 0.125em;
				font-size: 24px;
				color: $arrangementOfTheWeek-title-color;

				@include sf_pro_textbold;
			}

			&:hover {
				text-decoration: none;
			}
		}

		.details {
			.location {
				margin: 0 0 0.5em;
				font-size: 14px;
				color: $arrangementOfTheWeek-location-color;

				@include sf_pro_textlight;

				@include media-breakpoint-down(lg) {
					margin: 0;
				}

				i {
					font-size: 18px;
					color: $arrangementOfTheWeek-i-color;
				}

				span {
					line-height: 18px;
					vertical-align: top;
				}
			}

			.details-text {
				position: relative;
				max-height: 150px;
				margin: 0 0 0.5em 0;
				font-size: 14px;
				color: $arrangementOfTheWeek-detail-text-color;

				@include sf_pro_textlight;

				@include media-breakpoint-down(lg) {
					margin: 0 0 1.5em 0;
				}

				@include media-breakpoint-down(md) {
					display: none;
				}

				.hospitalityMessage {
					position: absolute;
					top: auto;
					bottom: 0;
					left: 0;
					z-index: 10;
				}

				div {
					max-height: inherit;
					padding-right: 15px;
					padding-bottom: 15px;

					@include media-breakpoint-up(lg) {
						overflow: hidden;
					}

					&:hover {
						@include media-breakpoint-up(lg) {
							padding-right: 0;
							overflow-y: auto;
						}
					}
				}

				&:after {
					@include media-breakpoint-up(lg) {
						position: absolute;
						bottom: 0;
						width: 100%;
						height: 50px;
						content: '';
						background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, $arrangementOfTheWeek-background 100%);
					}
				}

				&.discount {
					max-height: 127px;
					margin: 0 110px 0 0;

					div {
						@include media-breakpoint-between(md, lg) {
							max-height: 104px;
							padding: 0;
							margin-bottom: 23px;
							overflow: auto;
						}
					}

					.hospitalityMessage {
						bottom: -23px;
					}
				}
			}

			.price {
				position: relative;
				bottom: 0;
				margin: 0 0 0.5em;
				font-size: 14px;
				line-height: 1.4em;
				color: $arrangementOfTheWeek-price-before-text-color;

				@include media-breakpoint-up(md) {
					position: absolute;
				}

				@include media-breakpoint-up(lg) {
					margin: 0;
				}

				@include homePageArrangementOfTheWeekDiscountedPrice();

				b {
					display: inline-block;
					float: none;
					padding: 0.2em 0;
					margin: 5px 1px -11px 5px;
					font-size: 2em;
					line-height: 1em;
					color: $arrangementOfTheWeek-price-text-color;
					text-align: center;

					@include sf_pro_textbold;

					.price-withoutDiscount {
						font-size: 0.6em;
						text-decoration: line-through;
					}
				}
			}
		}

		.date {
			height: 100px;
			padding-top: 5px;
			text-align: center;
			text-transform: uppercase;
			background: $arrangementOfTheWeek-date-background;
			border-bottom: 1px solid $arrangementOfTheWeek-border-color;

			@media (max-width: (#{map.get($grid-breakpoints, lg) + 100})) {
				padding-top: 0;
			}

			@include media-breakpoint-between(lg, xxl) {
				height: 85px;
			}

			h4 {
				margin: 0;
				font-size: 50px;
				color: $arrangementOfTheWeek-date-color;
				text-align: center;

				@media (max-width: (#{map.get($grid-breakpoints, lg) + 100})) {
					font-size: 40px;
				}

				@include sf_pro_textbold;
			}

			p {
				@include sf_pro_textlight;
				margin: 0 10px;
				font-size: 14px;
				line-height: initial;
				color: $arrangementOfTheWeek-date-color;
				text-transform: uppercase;
			}
		}

		.in-price {
			height: 100px;
			padding-top: 20px;
			margin-bottom: 2px;
			text-align: center;
			background: $arrangementOfTheWeekin-Price-background;

			@include media-breakpoint-down(lg) {
				height: 75px;
				padding-top: 7px;
			}

			p {
				@include sf_pro_textlight;
				margin-bottom: 0.7em;
				font-size: 14px;
				color: $arrangementOfTheWeekin-Price-color;
				text-transform: uppercase;
			}

			ul {
				padding: 0;
				margin: 0;
				list-style: none;

				li {
					display: inline-block;

					span[class*="eventTripsIcon-"] {
						font-size: 20px;
						color: $arrangementOfTheWeekin-Price-color;
					}
				}
			}
		}

		a.bookBtn {
			box-sizing: border-box;
			display: inline-block;
			width: 100%;
			height: 45px;
			padding: 0;
			margin: 0;
			clear: both;
			font-size: 18px;
			font-weight: normal;
			line-height: 45px;
			color: $arrangementOfTheWeek-bookBtn-color;
			text-align: center;
			text-decoration: none;
			white-space: nowrap;
			vertical-align: top;
			background: $arrangementOfTheWeek-bookBtn-background;
			border-radius: 0;
			transition: background 0.25s ease 0s;

			@include sf_pro_textbold;

			&:hover {
				background: $arrangementOfTheWeek-bookBtn-hover-background;
			}
		}

		.images {
			position: absolute;
			bottom: 0;
			width: 100%;
			border-top: 1px solid $arrangementOfTheWeek-border-color;

			@include media-breakpoint-down(lg) {
				display: none;
			}

			.image-left, .image-right {
				padding: 0;
				border-left: 1px solid $arrangementOfTheWeek-border-color;

				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}