@import "../../general/import-general-only";

.paymentInstallmentPromotionBlock {
	margin: 25px auto;

	@include media-breakpoint-down(md) {
		margin: 20px auto;
	}

	.paymentInstallmentPromotion {
		padding: 20px;
		word-wrap: break-word;
		background: $gray-light;

		@include media-breakpoint-up(md) {
			padding: 30px;
		}

		.paymentInstallmentPromotionTitle {
			margin: 0 0 20px;
			font-size: 2em;
			font-weight: bold;
			line-height: 1.2em;
			color: $text-blue;

			.highLightedText {
				color: $secondary-green;

				.no-wrap {
					white-space: nowrap;
				}
			}

			@include media-breakpoint-up(md) {
				font-size: 41px;
				line-height: 49px;
			}
		}

		.description {
			margin-bottom: 20px;
			font-family: 'sf_pro_textregular';
			font-size: 16px;
			font-weight: 400;
			line-height: 19px;
			color: $text-blue;
		}

		@include media-breakpoint-up(lg) {
			.paymentInstallmentPromotionTitle {
				max-width: 70%;
			}

			.description {
				max-width: 63%;
			}
		}

		a {
			display: inline-block;
			padding: 10px;
			font-family: 'sf_pro_textbold';
			text-align: left;

			&.seeMatches {
				font-size: 16px;
				color: $white;
				background-color: $primary-blue;
				border-color: $primary-blue;
				border-radius: 5px;
			}

			&.learnMore {
				margin: 10px 0 0 0;
				font-size: 16px;
				color: $primary-blue;

				@include media-breakpoint-up(sm) {
					margin-left: 10px;
				}
			}
		}
	}
}
