$reviews-header-color: $primary-blue !default;
$reviews-border-right-color: $white !default;
$reviews-transparent-background: linear-gradient(transparent, rgba(0, 0, 0, 0.7)) !default;
$reviews-description-quote: $white !default;
$reviews-description-name: $white !default;
$reviews-description-club: $white !default;

h2 {
	margin: 50px 0 25px 0;
	font-size: 28px;
	color: $reviews-header-color;
	text-align: center;

	@include sf_pro_textbold;
}

.reviewTrustPilot {
	padding: 20px 0 0 0;

	* {
		font-family: 'sf_pro_textlight', Arial, sans-serif !important;
		font-weight: normal;
	}

	.ti-name {
		font-family: 'sf_pro_textsemibold', Arial, sans-serif !important;
	}
}

.reviewsContainer {
	margin: 0;

	@include media-breakpoint-down(lg) {
		display: block;
		width: auto;
		margin-right: -1.3%;
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;

	}

	.review {
		display: inline-block;
		float: left;
		height: 432px;
		padding: 0;
		margin: 0;
		border-right: 1px solid $reviews-border-right-color;

		@include media-breakpoint-up(lg) {
			&.col-sm-4 {
				max-width: 32.65%;
			}

			&:nth-child(1) {
				margin: 0 0 1% 0;
			}

			&:nth-child(2){
				margin: 0 1% 1% 1%;
			}

			&:nth-child(3){
				margin: 0 0 1% 0;
			}
		}

		&:nth-last-child(1) {
			border: none;
		}

		@include media-breakpoint-down(lg) {
			display: inline-block !important;
			flex: unset;
			float: left;
			width: 32%;
			max-width: unset;
			height: 250px;
			margin: 0 1.3% 0 0;
		}

		@include media-breakpoint-down(xl) {
			height: 374px;
		}

		@include media-breakpoint-down(lg) {
			height: 250px;
		}

		@include media-breakpoint-down(md) {
			height: 180px;
			font-size: 0.7em;
		}

		@include media-breakpoint-down(sm) {
			float: none;
			width: 90%;
			height: 350px;
			font-size: 1em;
		}

		@include media-breakpoint-down(xsm) {
			width: 90%;
			height: 250px;
		}

		.background {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: hidden;

			img {
				position:absolute;
				top: 50%;
				left: 50%;
				width: auto;
				height: 100%;
				transform: translate(-50%, -50%);

				@include media-breakpoint-down(lg) {
					width: 100%;
					height: auto;
				}
			}
		}

		.transparent-background {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background: $reviews-transparent-background;
		}

		.description {
			position: absolute;
			bottom: 0;
			height: auto;
			padding: 15px;
			white-space: normal;

			.quote {
				position: relative;
				padding-right: 15px;
				margin-bottom: 1.3em;
				font-size: 1em;
				font-weight: 600;
				line-height: 1.3em;
				color: $reviews-description-quote;
				white-space: normal;
				vertical-align: middle;
			}

			h3.name {
				margin: 0;
				font-size: 1.4em;
				line-height: 1.4em;
				color: $reviews-description-name;
				vertical-align: middle;

				@include sf_pro_textbold;
			}

			p.club {
				margin: 0;
				font-size: 0.9em;
				line-height: 1.2em;
				color: $reviews-description-club;
				vertical-align: middle;
			}
		}
	}
}

a.centeredCtaButton {
	text-decoration: none;
}