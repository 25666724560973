@import "../common/price-discount-mixins";
@import "../common/exception-for-responsive-image";

/* topArrangements block */
$topMatches-title-color: $secondary-green !default;
$topMatches-item-background: $gray-light !default;
$topMatches-item-border-color: $white !default;
$topMatches-item-background-hover: $secondary-green !default;
$topMatches-item-details-color: $primary-blue !default;
$topMatches-item-details-color-hover: $white !default;
$topMatches-item-details-date-color: $text-blue !default;
$topMatches-item-details-icons-color: $primary-blue !default;
$topMatches-item-details-price-discount-bg: $primary-blue !default;
$topMatches-item-details-price-withDiscount-color: $white !default;
$topMatches-item-details-price-withoutDiscount-color: $white !default;
$topMatches-item-details-price-withDiscount-color-2: $primary-blue !default;
$topMatches-item-details-price-withoutDiscount-color-2: $text-blue !default;
$topMatches-item-mobile-details-background: rgba(240, 241, 243, 0.88) !default;
$topMatches-item-mobile-details-hover-background: $secondary-green !default;
$topMatches-item-mobile-details-hover-color: $white !default;

/* recommendedArrangements block */
$topOffers-item-details-background: rgba(56, 67, 142, 0.88) !default;
$topOffers-item-details-title-color: $white !default;
$topOffers-item-hover-details-background: $secondary-green !default;
$topOffers-item-details-color: $primary-blue-light !default;
$topOffers-item-details-priceLabel-color: $primary-blue-light !default;
$topOffers-item-details-price-color: $white !default;
$topOffers-item-details-price-discount-bg: $white !default;
$topOffers-item-details-price-withDiscount-color: $primary-blue !default;
$topOffers-item-details-price-withoutDiscount-color: rgba(56, 67, 142, 0.88) !default;
$topOffers-item-hover-details-color: $white !default;
$topOffers-indicator-color: $gray-placeholder !default;
$topOffers-active-indicator-color: $gray-secondary !default;

.top_matches, .top_offers {

	%price-withoutDiscount {
		font-size: 0.6em;
		text-decoration: line-through;
	}

	h2 {
		display: block;
		padding: 0;
		margin: 1.5em 0 1em 0;
		font-size: 21px;
		color: $topMatches-title-color;
		text-align: left;

		@include sf_pro_textbold;
	}

	&.col-md-6 {
		margin: 0 auto;

		@include media-breakpoint-between(md, lg) {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
}


.top_matches {
	padding: 0;
	
	.item-container {
		position: relative;
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;

		@include media-breakpoint-up(sm) {
			overflow: unset;
			white-space: unset;
		}

		.item {
			position: relative;
			display: inline-block;
			width: 90%;
			height: auto;
			margin: 0 10px 0 0;

			@include media-breakpoint-up(sm) {
				float: left;
				width: 100%;
				height: 74.5px;
			}

			@include media-breakpoint-up(lg) {
				height: 89px;
			}

			@include media-breakpoint-up(xxl) {
				height: 108px;
			}

			background-color: $topMatches-item-background;
			border-bottom: 1px solid $topMatches-item-border-color;

			@include exceptionForFixSizedBoxes();

			a {
				text-decoration: none;

				.img {
					position: relative;
					z-index: 1;
					float: left;
					width: 100%;
					height: auto;

					@include media-breakpoint-up(sm) {
						width: 73.5px;
						height: 73.5px;
					}

					@include media-breakpoint-up(lg) {
						width: 88px;
						height: 88px;
					}

					@include media-breakpoint-up(xxl) {
						width: 107px;
						height: 107px;
					}

					img {
						max-width: 100%;
						height: auto;
					}
				}

				.details {
					position: absolute;
					bottom: -1;
					left: 0;
					z-index: 2;
					width: 100%;
					padding: 10px;
					color: $topMatches-item-details-color;
					background-color: $topMatches-item-mobile-details-background;

					@include media-breakpoint-up(sm) {
						position: unset;
						bottom: unset;
						left: unset;
						z-index: 0;
						padding: 5px 0 0 85px;
						background: none;
					}

					@include media-breakpoint-up(lg) {
						height: 100%;
						padding-top: 10px;
						padding-left: 110px;
					}

					@include media-breakpoint-up(xxl) {
						padding-left: 125px;
					}

					.name {
						max-width: 350px;
						font-size: 18.2px;
						line-height: 1.2em;
						white-space: normal;

						@include sf_pro_textbold;

						@include media-breakpoint-up(sm) {
							max-width: 300px;
							font-size: 11.2px;
						}

						@include media-breakpoint-up(md) {
							max-width: 350px;
						}

						@include media-breakpoint-up(lg) {
							max-width: 210px;
							font-size: 18.2px;
							line-height: 18px;
						}

						@include media-breakpoint-up(xl) {
							max-width: 195px;
						}

						@include media-breakpoint-up(xxl) {
							max-width: 260px;
						}
						
					}

					.date {
						font-size: 14.7px;
						line-height: 18px;
						color: $topMatches-item-details-date-color;

						@include sf_pro_textlight;

						@include media-breakpoint-up(sm) {
							font-size: 11.2px;
						}

						@include media-breakpoint-up(lg) {
							font-size: 16.8px;
						}
					}

					ul.inPrice {
						width: 100%;
						padding: 0;
						margin: 5px 0 0 0;
						font-size: 14.7px;
						color: $topMatches-item-details-date-color;
						text-transform: uppercase;

						@include sf_pro_textlight;

						@include media-breakpoint-up(sm) {
							font-size: 8.4px;
						}

						@include media-breakpoint-up(md) {
							font-size: 11.2px;
						}

						li {
							display: inline-block;
							width: 16px;
							margin: 0 5px 0 0;
							line-height: 16px;
							color: $topMatches-item-details-date-color;
							text-transform: uppercase;
							vertical-align: top;
							list-style: none;

							@include sf_pro_textlight;

							span[class*="eventTripsIcon-"] {
								font-size: 20px;
								color: $topMatches-item-details-icons-color;

								@include media-breakpoint-up(sm) {
									font-size: 16px;
								}
							}

							&.text {
								width: auto;
								margin-right: 5px;
								font-size: 14.7px;

								@include media-breakpoint-up(sm) {
									font-size: 8.4px;
								}

								@include media-breakpoint-up(lg) {
									font-size: 11.2px;
								}
							}
						}
					}

					> .price {
						position: absolute;
						right: 10px;
						bottom: 2px;
						z-index: 2;

						&:not(.discount) {
							.priceLabel {
								display: block;
								font-size: 8.4px;
								
								color: $topMatches-item-details-date-color;
								text-align: right;
								text-transform: uppercase;

								@include sf_pro_textlight;

								@include media-breakpoint-up(lg) {
									font-size: 11.2px;
								}
							}

							.price {
								display: block;
								font-size: 16.6px;
								text-align: right;

								@include sf_pro_textbold;

								@include media-breakpoint-up(lg) {
									font-size: 19.6px;
								}

								.price-withoutDiscount {
									@extend %price-withoutDiscount;
								}
							}
						}

						@include homePageDiscountedPrice($type: top5);
					}

					.inPrice .text {
						@include media-breakpoint-down(sm) {
							display: block;
						}
					}

					.topOffersHalfPayOption {
						position: absolute;
						top: unset;
						right: 0;
						bottom: 48px;
						float: unset;

						@include media-breakpoint-up(sm) {
							position: relative;
							top: -5px;
							float: right;
						}

						.commonMessage-item.withIcon {
							z-index: 2;
							padding: 0.2em 1.6em 0.2em 0.6em;
							margin: 0 0.2em;
							font-size: 0.8em;
							line-height: 1em;

							@include media-breakpoint-up(sm) {
								padding: 0em 1.3em 0em 0.3em;
								margin:  0.2em;
								font-size: 1em;
							}

							@include media-breakpoint-up(lg) {
								font-size: 1em;
							}

							@include media-breakpoint-up(xxl) {
								font-size: 1.1em;
							}
						}

						.commonMessage-item-icon {
							top: 2px;
							font-size: 1em;

							@include media-breakpoint-up(sm) {
								font-size: 0.8em;
							}
						}

						.commonMessage-item-text {
							padding-bottom: 1px;
							font-size: 0.8em;

							@include media-breakpoint-up(sm) {
								font-size: 0.5em;
							}

							@include media-breakpoint-up(lg) {
								font-size: 0.6em;
							}
						}
					}
				}
			}

			&:hover {
				background-color: $topMatches-item-background-hover;

				a {
					.details {
						color: $topMatches-item-details-color-hover;

						.date, .inPrice, .price:not(.discount) .priceLabel, .price.discountWithPaymentInstallmentsInfo * {
							color: $topMatches-item-details-color-hover;
						}

						.topOffersHalfPayOption {
							.commonMessage-item.withIcon {
								background: unset;
							}

							* {
								color: $topMatches-item-details-color-hover;
								border-color: $topMatches-item-details-color-hover;
							}
						}

						ul.inPrice li {
							color: $topMatches-item-details-color-hover;

							span[class*="eventTripsIcon-"] {
								color: $topMatches-item-details-color-hover;
							}

							.hospitalityMessageMarker {
								color: $topMatches-item-background-hover;
								background: $topMatches-item-details-color-hover;
							}
						}
					}

					&:hover {
						.details {
							color: $topMatches-item-mobile-details-hover-color;
							background-color: $topMatches-item-mobile-details-hover-background;

							.date, .inPrice, .price .priceLabel {
								color: $topMatches-item-mobile-details-hover-color;
							}

							ul.inPrice li {
								color: $topMatches-item-mobile-details-hover-color;

								svg {
									fill: $topMatches-item-mobile-details-hover-color;
								}
							}
						}
					}
				}
			}
		}
	}
}

.top_offers {
	position: relative;
	z-index: 1;

	.carousel {
		margin: 0 15px; 

		@include media-breakpoint-up(lg) {
			margin: 0 0 0 15px; 
		}

		.carousel-inner {
			.carousel-item {
				max-height: 465px;
				cursor: pointer;

				@include media-breakpoint-up(xxl) {
					max-height: 540px;
				}

				@include exceptionForFixSizedBoxes();

				PICTURE {
					@include media-breakpoint-between(md, lg) {
						display: block;
						height: 371px;
					}
				}

				img {
					width: 100%;
					height: auto;
				}

				.details {
					position: absolute;
					bottom: 0;
					width: 100%;
					padding: 10px 20px;
					color: $topOffers-item-details-color;
					background-color: $topOffers-item-details-background;

					.name {
						font-size: 18.2px;
						color: $topOffers-item-details-title-color;
						white-space: normal;

						@include sf_pro_textbold;
					}

					.date {
						font-size: 16.8px;
						line-height: 18px;
						color: $topOffers-item-details-color;

						@include sf_pro_textlight;

						@include media-breakpoint-down(sm) {
							font-size: 14.7px;
						}
					}

					ul.inPrice {
						width: 100%;
						padding: 0;
						margin: 10px 0 0 0;
						font-size: 14px;

						li {
							display: inline-block;
							width: 25px;
							margin: 0 0;
							line-height: 15px;
							color: $topOffers-item-details-color;
							text-transform: uppercase;
							vertical-align: middle;
							list-style: none;

							@include sf_pro_textlight;

							span[class*="eventTripsIcon-"] {
								font-size: 24px;
								color: $topOffers-item-details-color;
							}

							&.text {
								width: auto;
								margin-right: 5px;
							}
						}
					}

					> .price {
						position: absolute;
						right: 10px;
						bottom: 10px;
						z-index: 2;

						&:not(.discount) {
							.priceLabel {
								display: block;
								font-size: 14px;
								color: $topOffers-item-details-priceLabel-color;
								text-align: right;
								text-transform: uppercase;

								@include sf_pro_textlight;
							}

							.price {
								display: block;
								font-size: 24px;
								color: $topOffers-item-details-price-color;
								text-align: right;
								white-space: nowrap;

								@include sf_pro_textbold;

								.price-withoutDiscount {
									@extend %price-withoutDiscount;
								}
							}
						}

						@include homePageDiscountedPrice($type: top_offers);
					}

					.inPrice .text {
						@include media-breakpoint-down(sm) {
							display: block;
						}
					}
				}

				&:hover {
					.details {
						color: $topOffers-item-hover-details-color;
						background-color: $topOffers-item-hover-details-background;
						transition: background-color 0.3s ease 0s;

						.date {
							color: $topOffers-item-hover-details-color;
						}

						ul.inPrice li {
							color: $topOffers-item-hover-details-color;

							span[class*="eventTripsIcon-"] {
								color: $topOffers-item-hover-details-color;
							}

							.hospitalityMessageMarker {
								color: $topOffers-item-hover-details-background;
								background: $topOffers-item-hover-details-color;
							}
						}

						.price:not(.discount) .priceLabel, .price.discountWithPaymentInstallmentsInfo * {
							color: $topOffers-item-hover-details-color;
						}
					}
				}
			}
		}

		ol.carousel-indicators {
			position: relative;
			bottom: 0;
			margin: 10px 0;
			list-style-type: none;

			li {
				width: 12px;
				height: 12px;
				background-color: $topOffers-indicator-color;
				border-color: $topOffers-indicator-color;
				border-top: none;
				border-bottom: none;
				border-radius: 6px;

				&.active {
					background-color: $topOffers-active-indicator-color;
					border-color: $topOffers-active-indicator-color;
					border-radius: 6px;
				}
			}
		}

		.carousel-control-prev {
			left: -27px;
		}

		.carousel-control-next {
			right: -27px;
		}

		.carousel-control-prev, .carousel-control-next {
			width: auto;
			height: auto;
			padding: inherit;
			color: $gray-secondary;
			text-decoration: none;
			cursor: pointer;
			background: transparent;
			border: none;
			box-shadow: none;
			opacity: 1;

			i {
				font-size: 45px;
				text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
			}
		}
	}

	/* tablet */
	@include media-breakpoint-down(lg) {
		padding: 0 0 0 15px;

		.carousel {
			.carousel-inner {
				.carousel-item {
					.details {
						padding-right: 10px;
						padding-left: 10px;

						.name {
							font-size: 18.2px;
						}

						.date {
							font-size: 10.2px;
						}

						ul.inPrice {
							font-size: 14.7px;

							li {
								width: 20px;
								margin-right: 0px;

								span[class*="eventTripsIcon-"] {
									font-size: 20px;
								}
							}
						}

						.price {
							.priceLabel {
								font-size: 14px;
							}

							.price {
								font-size: 28px;

								.price-withoutDiscount {
									display: block;
									margin: 0px 0 -5px;
									line-height: 1em;
									text-align: right;
								}
							}
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		padding: 0;
	}

	/* mobile devices */
	@include media-breakpoint-down(sm) {
		.carousel {
			.carousel-inner {
				position: relative;
				overflow-x: auto;
				overflow-y: hidden;
				white-space: nowrap;

				.carousel-item {
					display: inline-block;
					float: unset;
					width: 90%;
					height: auto;
					max-height: none;
					margin: 0 10px 0 0;

					.details {
						.price {
							.priceLabel {
								@include media-breakpoint-down(sm) {
									font-size: 14.7px;
								}
							}

							.price {
								font-size: 1em;

								@include media-breakpoint-down(sm) {
									font-size: 14.7px;
								}
							}
						}
					}
				}
			}

			ol.carousel-indicators, .carousel-control-next, .carousel-control-prev {
				display: none;
			}
		}
	}
}