@import "../../general/import-general-only";

$lifetimeEvents-color: $text-blue !default;
$lifetimeEvents-background: $gray-light !default;
$lifetimeEvents-promotion-title-highlighted-color: $secondary-green !default;
$lifetimeEvents-title-icon-color: $gray-text-default !default;
$lifetimeEvents-event-background: $white !default;
$lifetimeEvents-event-border-color: $primary-blue-light !default;
$lifetimeEvents-arrangement-page-link-color: $tertiary-blue !default;

.lifetime-events-block {
	max-width: unset;
	padding: 20px 0;
	margin: 20px auto 40px;
	color: $lifetimeEvents-color;
	background: $lifetimeEvents-background;

	@include media-breakpoint-up(md) {
		padding: 60px 0;
		margin: 0 auto;
	}

	.lifetime-events-promotion {
		@include media-breakpoint-between(md, lg) {
			padding-right: 40px;
		}

		@include media-breakpoint-up(xl) {
			padding: 0;
		}
	}

	.promotionTitle {
		margin-bottom: 20px;
		font-size: 32px;

		@include sf_pro_textmedium;

		@include media-breakpoint-up(lg) {
			font-size: 40px;

			i {
				color: $lifetimeEvents-title-icon-color;
			}
		}

		.highlighted-text {
			color: $lifetimeEvents-promotion-title-highlighted-color;
		}
	}

	.lifetime-events {
		@include media-breakpoint-down(md) {
			padding: 0 var(--bs-gutter-x);
			margin-top: 20px;
		}

		.lifetime-event {
			padding: 10px 20px;
			background-color: $lifetimeEvents-event-background;
			border: 1px solid $lifetimeEvents-event-border-color;
			border-radius: 5px;
		}

		.lifetime-event-image-holder {
			text-align: center;
		}

		.lifetime-event-image {
			max-width: 50px;
			max-height: 50px;
		}

		.arrangementPageLinkHolder {
			align-items: center;

			@include media-breakpoint-up(lg) {
				padding-left: 5px;
				text-align: left;
			}

			.arrangementPageLink {
				color: $lifetimeEvents-arrangement-page-link-color;

				@include sf_pro_textmedium;

				i {
					position: absolute;
					top: 50%;
					right: 20px;
					font-size: 8px;
					transform: translateY(-50%);
				}
			}
		}
	}
}